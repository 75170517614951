<template>
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box" style="padding-top: 24px">
      <div class="content-main">
        <ayl-table :table="table">
          <div slot="ctrl-button">
            <el-button class="btn" @click="independentRecordOrder">提交</el-button>
            <el-button class="btn" style="margin-left: 20px" @click="onAdd">+ 新增</el-button>
          </div>
        </ayl-table>
      </div>
    </div>

    <!-- ----------编辑--------- -->
    <el-drawer
      title="编辑汇总信息"
      :visible.sync="editDrawerOn"
      :direction="editDirection"
      :before-close="editClose"
      size="70%"
    >
      <district-summary-edit
        :summaryId="summaryId"
        @coloseDrawer="coloseDrawer"
        :key="summaryConst"
      ></district-summary-edit>
    </el-drawer>

    <!------------ 详情 ------------->
    <el-drawer
      :title="`${detailBidName}标段详情`"
      :visible.sync="detailDrawerOn"
      :direction="detailDirection"
      :before-close="detailClose"
      :key="summaryConst1"
      size="70%"
    >
      <district-summary-details ref="summarys" :summaryId="summaryId"></district-summary-details>
    </el-drawer>

    <el-drawer
      :title="drawerFormData.title"
      :visible.sync="drawer"
      :direction="direction"
      size="80%"
    >
      <div>
        <span class="drawer-main" v-for="(item,idx) in drawerFormData.drawerBtn" :key="idx">
          <button
            @click="drawerClick(item.name)"
            :class="drawerKey === item.name ? 'drawer-btn1' : 'drawer-btn'"
          >{{ item.name }}</button>
        </span>
      </div>
      <!-- 基本信息 -->
      <div class="drawer-contract" v-if="drawerKey === '基本信息'">
        <el-form :model="drawerContractForm" ref="form" :inline="true" label-position="left" style>
          <el-form-item label="标段类型:" class="drawer-contract-item1">
            <span class="drawer-contract-span">{{ drawerContractForm.name }}</span>
          </el-form-item>
          <el-form-item label="标段编号:" class="drawer-contract-item1">
            <span class="drawer-contract-span">{{ drawerContractForm.name }}</span>
          </el-form-item>
          <el-form-item label="标段名称:" class="drawer-contract-item1">
            <span class="drawer-contract-span">{{ drawerContractForm.name }}</span>
          </el-form-item>
          <el-form-item label="面积:" class="drawer-contract-item1">
            <span class="drawer-contract-span">{{ drawerContractForm.name }}(平方米)</span>
          </el-form-item>
          <el-form-item label="主管单位:" class="drawer-contract-item1">
            <span class="drawer-contract-span">{{ drawerContractForm.name }}</span>
          </el-form-item>
          <el-form-item label="养护项目组:" class="drawer-contract-item1">
            <span class="drawer-contract-span">{{ drawerContractForm.name }}</span>
          </el-form-item>
          <el-form-item label="养护企业:" class="drawer-contract-item1">
            <span class="drawer-contract-span">{{ drawerContractForm.name }}</span>
          </el-form-item>
          <el-form-item label="人员(不通过/总数):" class="drawer-contract-item1">
            <span class="drawer-contract-span">{{ drawerContractForm.name }}</span>
          </el-form-item>
          <el-form-item label="车辆(不通过/总数):" class="drawer-contract-item1">
            <span class="drawer-contract-span">{{ drawerContractForm.name }}</span>
          </el-form-item>
        </el-form>
      </div>
      <div v-if="drawerKey === '基本信息'" class="drawer-hr"></div>
      <div v-if="drawerKey === '基本信息'" class="drawer-map">
        <div class="drawer-map-title">标段范围</div>
        <div id="container" style="width: 100%; height: 376px; margin-top: 15px"></div>
      </div>
      <!-- 标段合同 -->
      <div class="drawer-contract" v-if="drawerKey === '标段合同'">
        <el-form :model="drawerContractForm" ref="form" :inline="true" label-position="left">
          <el-form-item label="合同名称:" class="drawer-contract-item">
            <span class="drawer-contract-span">{{ drawerContractForm.name }}</span>
          </el-form-item>
          <el-form-item label="合同编号:" class="drawer-contract-item">
            <span class="drawer-contract-span">{{ drawerContractForm.name }}</span>
          </el-form-item>
          <el-form-item label="合同周期:" class="drawer-contract-item">
            <span class="drawer-contract-span">{{ drawerContractForm.name }}</span>
          </el-form-item>
          <el-form-item label="采购单位:" class="drawer-contract-item">
            <span class="drawer-contract-span">{{ drawerContractForm.name }}</span>
          </el-form-item>
          <el-form-item label="甲方联系人:" class="drawer-contract-item1">
            <span class="drawer-contract-span">{{ drawerContractForm.name }}</span>
          </el-form-item>
          <el-form-item label="联系方式:" class="drawer-contract-item1">
            <span class="drawer-contract-span">{{ drawerContractForm.name }}</span>
          </el-form-item>
          <el-form-item label="乙方联系人:" class="drawer-contract-item1">
            <span class="drawer-contract-span">{{ drawerContractForm.name }}</span>
          </el-form-item>
          <el-form-item label="联系方式:" class="drawer-contract-item1">
            <span class="drawer-contract-span">{{ drawerContractForm.name }}</span>
          </el-form-item>
        </el-form>
      </div>
      <!-- 人员 -->
      <div class="drawer-contract" v-if="drawerKey === '人员'">
        <ayl-table :table="personnelTable">
          <div slot="ctrl-button">
            <el-button class="btn" @click="personnelTransferred()">调入</el-button>
            <el-button class="btn" style="margin-left: 20px" @click="onAdd">调出</el-button>
            <!-- <el-button class="btn" style="margin-left: 20px" @click="onExport">导出</el-button> -->
          </div>
        </ayl-table>
      </div>
      <!-- 车辆 -->
      <div class="drawer-contract" v-if="drawerKey === '车辆'">
        <ayl-table :table="personnelTable">
          <div slot="ctrl-button">
            <el-button class="btn" @click="vehicleTransferred()">调入</el-button>
            <el-button class="btn" style="margin-left: 20px" @click="onAdd">调出</el-button>
            <!-- <el-button class="btn" style="margin-left: 20px" @click="onExport">导出</el-button> -->
          </div>
        </ayl-table>
      </div>
      <!-- 城中村 -->
      <div class="drawer-contract" v-if="drawerKey === '城中村'">
        <ayl-table :table="personnelTable">
          <div slot="ctrl-button">
            <el-button class="btn" @click="urbanVillageTransferred()">调入</el-button>
            <el-button class="btn" style="margin-left: 20px" @click="onAdd">调出</el-button>
            <!-- <el-button class="btn" style="margin-left: 20px" @click="onExport">导出</el-button> -->
          </div>
        </ayl-table>
      </div>
      <!-- 市政道路 -->
      <div class="drawer-contract" v-if="drawerKey === '市政道路'">
        <ayl-table :table="personnelTable">
          <div slot="ctrl-button">
            <el-button class="btn" @click="cityRoadTransferred()">调入</el-button>
            <el-button class="btn" style="margin-left: 20px" @click="onAdd">调出</el-button>
            <!-- <el-button class="btn" style="margin-left: 20px" @click="onExport">导出</el-button> -->
          </div>
        </ayl-table>
      </div>
      <!-- 环保垃圾屋 -->
      <div class="drawer-contract" v-if="drawerKey === '环保垃圾屋'">
        <ayl-table :table="personnelTable">
          <div slot="ctrl-button">
            <el-button class="btn" @click="garbageGreenTransferred()">调入</el-button>
            <el-button class="btn" style="margin-left: 20px" @click="onAdd">调出</el-button>
            <!-- <el-button class="btn" style="margin-left: 20px" @click="onExport">导出</el-button> -->
          </div>
        </ayl-table>
      </div>
      <!-- 垃圾中转站 -->
      <div class="drawer-contract" v-if="drawerKey === '垃圾中转站'">
        <ayl-table :table="personnelTable">
          <div slot="ctrl-button">
            <el-button class="btn" @click="garbageTransferTransferred()">调入</el-button>
            <el-button class="btn" style="margin-left: 20px" @click="onAdd">调出</el-button>
            <!-- <el-button class="btn" style="margin-left: 20px" @click="onExport">导出</el-button> -->
          </div>
        </ayl-table>
      </div>
      <!-- 垃圾收集点 -->
      <div class="drawer-contract" v-if="drawerKey === '垃圾收集点'">
        <ayl-table :table="personnelTable">
          <div slot="ctrl-button">
            <el-button class="btn" @click="garbageCollectionTransferred()">调入</el-button>
            <el-button class="btn" style="margin-left: 20px" @click="onAdd">调出</el-button>
            <!-- <el-button class="btn" style="margin-left: 20px" @click="onExport">导出</el-button> -->
          </div>
        </ayl-table>
      </div>
      <!-- 国土地块 -->
      <div class="drawer-contract" v-if="drawerKey === '国土地块'">
        <ayl-table :table="personnelTable">
          <div slot="ctrl-button">
            <el-button class="btn" @click="landTransfer()">调入</el-button>
            <el-button class="btn" style="margin-left: 20px" @click="onAdd">调出</el-button>
            <!-- <el-button class="btn" style="margin-left: 20px" @click="onExport">导出</el-button> -->
          </div>
        </ayl-table>
      </div>
      <!-- 公厕 -->
      <div class="drawer-contract" v-if="drawerKey === '公厕'">
        <ayl-table :table="personnelTable">
          <div slot="ctrl-button">
            <el-button class="btn" @click="publicToiletsTransfer()">调入</el-button>
            <el-button class="btn" style="margin-left: 20px" @click="onAdd">调出</el-button>
            <!-- <el-button class="btn" style="margin-left: 20px" @click="onExport">导出</el-button> -->
          </div>
        </ayl-table>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import DistrictSummaryEdit from "./district-summary-edit";
import DistrictSummaryDetails from "./district-summary.details";
export default {
  components: {
    DistrictSummaryEdit,
    DistrictSummaryDetails,
  },
  data() {
    const vm = this;
    return {
      summaryConst: 0,
      summaryConst1:0,
      common_getBidNameAndIdList: [true, 0, null], // 标段管理下拉
      nav: [{ name: "标段信息" }],

      // 编辑
      editDrawerOn: false,
      editDirection: "rtl",

      // 详情
      detailDrawerOn: false,
      detailDirection: "rtl",
    
      summaryId: null,
      detailBidName: null,

      drawer: false,
      direction: "rtl",
      drawerKey: "基本信息", //标识
      drawerContractForm: {
        name: "测试测试测试测试测试",
      },
      map: null,
      drawerFormData: {
        title: "标题",
        //按钮信息
        drawerBtn: [
          {
            name: "基本信息",
          },
          {
            name: "标段合同",
          },
          {
            name: "人员",
          },
          {
            name: "车辆",
          },
          {
            name: "城中村",
          },
          {
            name: "市政道路",
          },
          {
            name: "垃圾中转站",
          },
          {
            name: "垃圾收集点",
          },
          {
            name: "国土地块",
          },
          {
            name: "环保垃圾屋",
          },
          {
            name: "公厕",
          },
        ],
      },
      table: {
        api: vm.$api_hw.districtInfo_bidManagePage,
        query: { bidId: null },
        // 是否显示分页
        // hidePagination: true,
        // 表格查询的条件组件
        searchData: [
          {
            type: "cascader_radio",
            title: "标段名称",
            model: "bidId",
            placeholder: "请选择",
            option: [],
          },
        ],
        columns: [
          {
            type: "selection",
            width: "50px",
          },
          {
            title: "序号",
            width: "50px",
            $index: "index",
          },
          {
            title: "标段编号",
            showTooltip: true,
            render(h, ctx) {
              return h("span", [
                h(
                  "span",
                  {
                    class: {
                      "table-view-hw": true,
                    },
                    on: {
                      click: vm.previewDistrictDetails.bind(this, ctx.row),
                    },
                  },
                  ctx.row.bidNum || "- -"
                ),
              ]);
            },
          },
          {
            title: "标段名称",
            // width: "180px",
            showTooltip: true,
            key: "bidName",
          },
          {
            title: "面积(m²)",
            key: "bidContractArea",
            // filter: "str2ymd"
            // width: "160px"
          },
          {
            title: "项目经理",
            // width: "120px",
            key: "bidPm",
          },
          {
            title: "项目经理联系方式",
            width: "180px",
            key: "bidPmPhone",
          },
          {
            title: "状态",
            key: "checkStatus",
            render: (h, ctx) => {
              if (ctx !== null) {
                let text = {
                  0: "未提交",
                  1: "待审核",
                  2: "通过",
                  3: "不通过",
                }[ctx.row.checkStatus];
                return h("span", text);
              } else {
                return h("span", "--");
              }
            },
          },
          {
            title: "审核意见",
            key: "checkResult",
          },
          {
            title: "操作",
            width: "215px",
            align: "center",
            render(h, ctx) {
              return h("span", [
                h(
                  "span",
                  {
                    class: {
                      "table-view-hw": true,
                    },
                    on: {
                      click: vm.onEdit.bind(this, ctx.row),
                    },
                  },
                  "编辑"
                ),
                h(
                  "span",
                  {
                    class: {
                      "table-view-line-hw": true,
                    },
                    on: {
                      click: vm.editDrawer.bind(this, ctx.row),
                    },
                  },
                  "编辑汇总信息"
                ),
                h(
                  "span",
                  {
                    class: {
                      "table-view-line-hw": true,
                    },
                    on: {
                      click: vm.detailDrawer.bind(this, ctx.row),
                    },
                  },
                  "详情"
                ),
              ]);
            },
          },
        ],
      },
      //人员表格
      personnelTable: {
        api: vm.$api.OrderList,
        query: {
          type: 1,
          orderType: null,
          carBlong: null,
          queryContent: null,
        },
        // 是否显示分页
        // hidePagination: true,
        // 表格查询的条件组件
        searchData: [
          {
            type: "input",
            model: "queryContent",
            placeholder: "请输入姓名",
          },
        ],
        columns: [
          {
            type: "selection",
            width: "50px",
          },
          {
            title: "序号",
            width: "50px",
            $index: "index",
          },
          {
            title: "姓名",
            render(h, ctx) {
              return h("span", [
                h(
                  "span",
                  {
                    style: {
                      color: vm.$config.color_hw,
                    },
                  },
                  ctx.row.orderId || "- -"
                ),
              ]);
            },
          },
          {
            title: "联系方式",
            width: "180px",
            showTooltip: true,
            key: "phone",
          },
          {
            title: "人员类型",
            key: "",
            filter: "str2ymd",
            width: "160px",
          },
          {
            title: "变更类型",
            width: "120px",
            key: "",
          },
          {
            title: "状态",
            width: "180px",
            key: "",
          },
          {
            title: "审核意见",
            key: "",
          },
        ],
      },
    };
  },
  watch: {
    editDrawerOn() {
      this.summaryConst = this.editDrawerOn
        ? this.summaryConst + 1
        : this.summaryConst - 1;
    },
  },

  methods: {
    //打开抽屉
    changeDrawer(v) {
      console.log(v);
      this.drawer = true;
      this.$nextTick(() => {
        this.initAmap();
      });
    },

    editDrawer(val) {
      this.editDrawerOn = true;
      this.summaryId = val.bidId;
    },
    // 编辑汇总抽屉关闭
    editClose() {
      // this.$confirm("确认关闭？")
      //   .then(_ => {
      this.editDrawerOn = false;
      // })
      // .catch(_ => {});
    },
    // 子组件执行父组件方法
    coloseDrawer() {
      this.editDrawerOn = false;
      this.$search(this.table);
    },

    // -----------详情-----------
    detailDrawer(val) {
      this.detailDrawerOn = true;
      this.summaryId = val.bidId;
      this.detailBidName = val.bidName;
      this.summaryConst1++
      // this.$refs.summarys.getDetailsInfo();
    },
    // 详情汇总抽屉关闭
    detailClose() {
      // this.$confirm("确认关闭？")
      //   .then(_ => {
      this.detailDrawerOn = false;
      // })
      // .catch(_ => {});
    },

    //选择按钮信息
    async drawerClick(val) {
      this.drawerKey = val;
      if (val === "人员") {
        await this.$search(this.personnelTable);
      } else if (val === "基本信息") {
        this.$nextTick(() => {
          this.initAmap();
        });
      }
      console.log(val);
    },
    independentRecordOrder() {},
    //map
    initAmap() {
      // 构造点标记
      var marker = new AMap.Marker({
        icon: "https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png",
        //position: [this.otherTitleData.longitude,this.otherTitleData.latitude]
      });
      // 构造矢量圆形
      var circle = new AMap.Circle({
        //center:[this.otherTitleData.longitude,this.otherTitleData.latitude], // 圆心位置
        radius: 30, //半径
        strokeColor: "#b1d0f2", //线颜色
        strokeOpacity: 1, //线透明度
        strokeWeight: 1, //线粗细度
        fillColor: "#d7e4f0", //填充颜色
        fillOpacity: 0.8, //填充透明度
      });
      //初始化地图
      this.map = new AMap.Map("container", {
        //center:[this.otherTitleData.longitude,this.otherTitleData.latitude],
        resizeEnable: true,
        zoom: 10,
      });
      this.map.add([marker, circle]);
    },
    //人员调入
    personnelTransferred() {
      this.drawer = false;
      this.$router.push({
        path: "/district-management/district-info/personnel-transferred",
      });
    },
    //车辆调入
    vehicleTransferred() {
      this.drawer = false;
      this.$router.push({
        path: "/district-management/district-info/vehicle-transferred",
      });
    },
    //城中村调入
    urbanVillageTransferred() {
      this.drawer = false;
      this.$router.push({
        path: "/district-management/district-info/urban-village-transferred",
      });
    },
    //市政道路调入
    cityRoadTransferred() {
      this.drawer = false;
      this.$router.push({
        path: "/district-management/district-info/city-road-transferred",
      });
    },
    //环保垃圾屋调入
    garbageGreenTransferred() {
      this.drawer = false;
      this.$router.push({
        path: "/district-management/district-info/garbage-green-transferred",
      });
    },
    //垃圾中转站调入
    garbageTransferTransferred() {
      this.drawer = false;
      this.$router.push({
        path: "/district-management/district-info/garbage-transfer-transferred",
      });
    },
    //垃圾收集点
    garbageCollectionTransferred() {
      this.drawer = false;
      this.$router.push({
        path:
          "/district-management/district-info/garbage-collection-transferred",
      });
    },
    //国土地块调入
    landTransfer() {
      this.drawer = false;
      this.$router.push({
        path: "/district-management/district-info/land-transfer",
      });
    },
    //公厕调入
    publicToiletsTransfer() {
      this.drawer = false;
      this.$router.push({
        path: "/district-management/district-info/public-toilets-transfer",
      });
    },
    onAdd() {
      this.$router.push({
        path: "/district-management/district-info/add-district",
      });
    },
    // eslint-disable-next-line no-unused-vars
    onEdit(val) {
      this.$router.push({
        path: "/district-management/district-info/edit-district",
        query: { id: val.bidId },
      });
    },
    /**
     * 查看标段信息
     */
    previewDistrictDetails(val) {
      this.$router.push({
        path: "/district-management/district-info/district-details",
        query: { id: val.bidId },
      });
    },
  },
  async activated() {
    // 获取列表信息
    await this.$search(this.table);
  },
};
</script>

<style lang='sass' scoped>
// .btn
//   width: 64px
//   height: 28px
//   border: 1px solid rgba(26,188,156,1)
//   border-radius: 4px
//   color: #1ABC9C
  
// .drawer-main
//   margin: 5px 15px
//   .drawer-btn
//     width: 92px
//     height: 36px
//     margin-bottom: 10px
//     background: rgba(237,237,237,1)
//     border-radius: 2px
//     outline: none
//     border: 0
//     &:hover
//       margin-bottom: 10px
//       background: rgba(26,188,156,1)
//       border-radius: 2px
//       border: 0
//       color: #fff
//     &:before
//       margin-bottom: 10px
//       background: rgba(26,188,156,1)
//       border-radius: 2px
//       border: 0
//       color: #fff
//   .drawer-btn1
//     width: 92px
//     height: 36px
//     margin-bottom: 10px
//     background: rgba(26,188,156,1)
//     border-radius: 2px
//     outline: none
//     border: 1px solid transparent
//     color: #fff

// .drawer-contract
//   margin: 30px 15px 10px
//   .drawer-contract-item
//     width: 100%
//     height: 1px
//     background: rgba(232,232,232,1)
//   .drawer-map
//     margin: 20px 15px 10px
//     .drawer-map-title
//       color: rgba(46,48,51,1)
//   /deep/.el-button--success 
//       color: #FFFFFF
//       background-color: rgba(26,188,156,1)
//       border-color: rgba(26,188,156,1)
.btn
  width: 64px
  height: 28px
  border: 1px solid rgba(26,188,156,1)
  border-radius: 4px
  color: #1ABC9C
/deep/ .el-drawer__header
  position: fixed
  width: 100%
  margin-bottom: 0px
  padding: 15px
  color: #222
  font-size: 24px
  background-color: #fff
  box-shadow: 0 1px 3px rgba(0,0,0,0.2)
  z-index: 1
/deep/ .el-drawer__close-btn
  position: fixed
  right: 10px;
  z-index: 2
/deep/ .el-drawer
  overflow: scroll;
</style>
